export class BaseException {
    public static DEFAULT_ERROR_MSG = "Entschuldigung, es ist ein Fehler aufgetreten.";

    public constructor(
        private readonly message: string,
    ) {
    }

    public getMessage(): string {
        return this.message ? this.message : BaseException.DEFAULT_ERROR_MSG;
    }
}
