import type {Tag} from "./Tag";

export class BenachrichtigungTags {
    public static AENDERUNG: Tag = {bezeichnung: "aenderung"};

    public static AUFGABE: Tag = {bezeichnung: "aufgabe"};

    public static AUSBILDUNG: Tag = {bezeichnung: "ausbildung"};

    public static DIENST: Tag = {bezeichnung: "dienst"};

    public static DOKUMENT: Tag = {bezeichnung: "dokument"};

    public static EINTEILUNG: Tag = {bezeichnung: "einteilung"};

    public static ENTFERNT: Tag = {bezeichnung: "entfernt"};

    public static FREIE_NACHRICHT: Tag = {bezeichnung: "freie_nachricht"};

    public static GEBURTSTAG: Tag = {bezeichnung: "geburtstag"};

    public static GELESEN: Tag = {bezeichnung: "gelesen"};

    public static HANDLUNGSBEDARF: Tag = {bezeichnung: "handlungsbedarf"};

    public static KURS: Tag = {bezeichnung: "kurs"};

    public static MATERIAL: Tag = {bezeichnung: "material"};

    public static NEU: Tag = {bezeichnung: "neu"};

    public static PERSOENLICH: Tag = {bezeichnung: "persoenlich"};

    public static PERSONAL: Tag = {bezeichnung: "personal"};

    public static PINNWAND: Tag = {bezeichnung: "pinnwand"};

    public static RECHNUNG: Tag = {bezeichnung: "rechnung"};

    public static SMS: Tag = {bezeichnung: "sms"};

    public static SONSTIGES: Tag = {bezeichnung: "sonstiges"};

    public static SYSTEM: Tag = {bezeichnung: "system"};

    public static UNGELESEN: Tag = {bezeichnung: "ungelesen"};

    public static TERMIN: Tag = {bezeichnung: "termin"};

    public static TERMINABSPRACHE: Tag = {bezeichnung: "terminabsprache"};

    public static WARNUNG: Tag = {bezeichnung: "warnung"};

    public static UEBERPRUEFUNG: Tag = {bezeichnung: "ueberpruefung"};

    public static get(tagbezeichnung: string): Tag {
        switch (tagbezeichnung) {
            case "aenderung": return this.AENDERUNG;
            case "aufgabe": return this.AUFGABE;
            case "ausbildung": return this.AUSBILDUNG;
            case "dienst": return this.DIENST;
            case "dokument": return this.DOKUMENT;
            case "einteilung": return this.EINTEILUNG;
            case "entfernt": return this.ENTFERNT;
            case "freie_nachricht": return this.FREIE_NACHRICHT;
            case "geburtstag": return this.GEBURTSTAG;
            case "gelesen": return this.GELESEN;
            case "handlungsbedarf": return this.HANDLUNGSBEDARF;
            case "kurs": return this.KURS;
            case "material": return this.MATERIAL;
            case "neu": return this.NEU;
            case "persoenlich": return this.PERSOENLICH;
            case "personal": return this.PERSONAL;
            case "pinnwand": return this.PINNWAND;
            case "rechnung": return this.RECHNUNG;
            case "sms": return this.SMS
            case "sonstiges": return this.SONSTIGES;
            case "system": return this.SYSTEM;
            case "termin": return this.TERMIN;
            case "terminabsprache": return this.TERMINABSPRACHE;
            case "ueberpruefung": return this.UEBERPRUEFUNG;
            case "ungelesen": return this.UNGELESEN;
            case "warnung": return this.WARNUNG;
            default: return this.SONSTIGES;
        }
    }
}
