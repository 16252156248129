import Organisation from "./models/api/Organisation";
import User from "./models/api/User";

export default class Context {
    private organisation_field: Organisation;

    private user_field: User;

    private simulated_field: boolean;

    public constructor(
        organisation?: Organisation,
        user?: User,
        simulated?: boolean,
    ) {
        this.organisation_field = organisation ?? new Organisation();
        this.user_field = user ?? new User();
        this.simulated_field = simulated ?? false;
    }

    public get user(): User {
        return this.user_field;
    }

    public set user(value: User) {
        this.user_field = value;
    }

    public get organisation(): Organisation {
        return this.organisation_field;
    }

    public set organisation(value: Organisation) {
        this.organisation_field = value;
    }

    public get simulated(): boolean {
        return this.simulated_field;
    }

    public set simulated(value: boolean) {
        this.simulated_field = value;
    }
}
