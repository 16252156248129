import type {FreieBenachrichtigung} from "./FreieBenachrichtigung";
import FreieBenachrichtigungApiAction from "./coloquent/FreieBenachrichtigungApiAction";
import type {FreieBenachrichtigungEndpoint} from "./FreieBenachrichtigungEndpoint";
import type {SaveResponse} from "coloquent";

export class FreieBenachrichtigungEndpointImpl implements FreieBenachrichtigungEndpoint {
    // eslint-disable-next-line class-methods-use-this
    public async sendBenachrichtigung(
        freieBenachrichtigung: FreieBenachrichtigung,
    ): Promise<number> {
        return new Promise((resolve, reject) => {
            const benachrichtigung = new FreieBenachrichtigungApiAction();
            benachrichtigung.setEmpfaenger(freieBenachrichtigung.empfaenger);
            benachrichtigung.setSendPush(freieBenachrichtigung.pushSenden);
            benachrichtigung.setText(freieBenachrichtigung.text);
            benachrichtigung
                .create()
                .then((response: SaveResponse<FreieBenachrichtigungApiAction>) => {
                    resolve((response.getModel() as FreieBenachrichtigungApiAction).getResult().anzahl_gesendet);
                })
                .catch(reject);
        });
    }
}
