import {CouldNotPerformApiCallException} from "../CouldNotPerformApiCallExpeption";
import {ErrorMessages} from "../../../ErrorMessages";
import Hilfe from "./Hilfe";
import type {HilfeEndpoint} from "./HilfeEndpoint";
import type {HilfeQuery} from "./HilfeQuery";
import type {HilfeRessource} from "./HilfeRessource";
import type {SingularResponse} from "coloquent";

export class HilfeEndpointImpl implements HilfeEndpoint {
    public async getHilfeRessource(query: HilfeQuery): Promise<HilfeRessource> {
        return new Promise((resolve, reject) => {
            Hilfe
                .find(query.hilfeId)
                .then((response: SingularResponse<Hilfe>) => {
                    const hilfe = response.getData()!;
                    const hilfeRessource = {
                        getTitel: () => hilfe.getTitel(),
                        getInhalt: () => hilfe.getInhalt(),
                    } as HilfeRessource;
                    resolve(hilfeRessource);
                })
                .catch(() => {
                    reject(new CouldNotPerformApiCallException(ErrorMessages.REQUEST_HILFE));
                });
        });
    }
}
