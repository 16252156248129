import {Model} from "coloquent";

export default class User extends Model {
    protected jsonApiType = "users";

    protected pageSize = 30;

    public getJsonApiBaseUrl(): string {
        return "/api_private";
    }

    public getUserId(): string {
        return String(this.getAttribute("user_id"));
    }

    public setUserId(user_id: string): void {
        this.setAttribute("user_id", user_id);
    }

    public getVorName(): string {
        return String(this.getAttribute("vorname"));
    }

    public setVorName(vorname: string): void {
        this.setAttribute("vorname", vorname);
    }

    public getName(): string {
        return String(this.getAttribute("name"));
    }

    public setName(name: string): void {
        this.setAttribute("name", name);
    }

    public getUserName(): string {
        return String(this.getAttribute("username"));
    }

    public setUserName(username: string): void {
        this.setAttribute("username", username);
    }
}
