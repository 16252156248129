import {Model} from "coloquent";

export default class Hilfe extends Model {
    protected jsonApiType = "hilfe";

    protected pageSize = 1;

    public getJsonApiBaseUrl(): string {
        return "/api_private";
    }

    public getTitel(): string {
        return String(this.getAttribute("titel"));
    }

    public getInhalt(): string {
        return String(this.getAttribute("inhalt"));
    }
}
